:root {
	font-family: 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color-scheme: light dark;
	color: #444;
	background: #fafafa;
}

@media (prefers-color-scheme: dark) {
	:root {
		color: #fff;
		background: #1c1c1c;
	}
}

body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
}

.fakeTextbox {
	border: 1px solid currentColor;
	border-radius: 3px;
	color: #222222;
	padding: 4px;
	margin: 0.3em;
	background-color: #DDDDDDCC;
	font-family: 'Karla', sans-serif;
}

.fakeTextbox .insertionPoint {
	content: "\200C";
	border-left: 1px solid currentColor;
	font-weight: 100;
	letter-spacing: 0;
}

blockquote.chatbubbles {
	margin-left: auto;
	margin-right: auto;
	max-width: 450px;
	width: calc(100% - 20px);
	box-sizing: border-box;
	border-radius: 30px;
	border-width: 30px 10px;
	border-style: solid;
	font-size: 0.95em;
	padding: 0;
}

blockquote.chatbubbles {
	border-color: #222222;
	background-color: #dfbbf7;
}

blockquote.chatbubbles div.header {
	background-color: #ad75db;
	border-bottom-color: #532975;
}

blockquote.chatbubbles p {
	color: #222222;
}

blockquote.chatbubbles div.chatbubble {
	color: #b483e2;
}

blockquote.chatbubbles div.chatbubble.self {
	color: #53daaf;
}

blockquote.chatbubbles {
	background-color: #1c061d;
	border-color: #2c2c2c;
}

blockquote.chatbubbles div.header {
	background-color: hsl(323, 100%, 15%);
	border-bottom-color: hsl(323, 65%, 28%);
}

blockquote.chatbubbles div.chatbubble p, blockquote.chatbubbles div.system p, blockquote.chatbubbles p.chatauthor, blockquote.chatbubbles p.datestamp, blockquote.chatbubbles div.header p {
	color: #DDDDDD;
}

blockquote.chatbubbles div.chatbubble {
	color: #361653;
}

blockquote.chatbubbles div.chatbubble.self {
	color: #094431;
}

blockquote.chatbubbles div.header {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	padding: 0.2em 0.5em;
}

blockquote.chatbubbles div.header p.chatname {
	font-size: 1.5em;
	font-family: 'Braah One', sans-serif;
	font-weight: bold;
	margin: 0.2em 0 -0.2em;
}

blockquote.chatbubbles p.chatauthor {
	font-family: 'Braah One', sans-serif;
	font-weight: bold;
	margin: 0.5em 0 -0.2em;
}

blockquote.chatbubbles p.datestamp {
	font-family: 'Karla', sans-serif;
	font-weight: bold;
	text-align: center;
	opacity: 70%;
	margin: 0.5em 0 0.2em;
}

blockquote.chatbubbles div.chatbubble p {
	font-family: 'Karla', sans-serif;
}

blockquote.chatbubbles div.header p.subtitle {
	font-family: 'Karla', sans-serif;
	margin: 0.2em 0;
}

blockquote.chatbubbles div.chatbubble {
	position: relative;
	background-color: currentColor;
	border-radius: 5px;
	width: fit-content;
	min-height: 20px;
	padding: 0.5em 0.5em;
	margin: 0.2em 0;
}

blockquote.chatbubbles div.chatbubble.loading {
	width: 120px;
	height: 120px;
}

blockquote.chatbubbles div.fakeTextbox {
	margin: 0.2em 5%;
}

blockquote.chatbubbles div.chatbubble.typing p {
	font-size: 0.8em;
}

blockquote.chatbubbles div.chatbubble.emojionly p {
	font-size: 2em;
}

blockquote.chatbubbles div.chatbubble p {
	z-index: 1;
}

blockquote.chatbubbles div.chatbubble p:first-child,
blockquote.chatbubbles div.fakeTextbox p:first-child {
	margin-top: 0;
}

blockquote.chatbubbles div.chatbubble p:last-child,
blockquote.chatbubbles div.fakeTextbox p:last-child {
	margin-bottom: 0;
}

blockquote.chatbubbles div.system {
	text-align: center;
	font-family: 'Karla', sans-serif;
	font-style: italic;
	margin: 0.5em 0.2em;
}

blockquote.chatbubbles p.chatauthor {
	margin-left: 5%;
	margin-right: 5%;
	text-align: left;
	font-size: smaller;
	font-weight: bold;
	font-family: 'Braah One', sans-serif;
}

blockquote.chatbubbles p.chatauthor.self {
	text-align: right;
}

blockquote.chatbubbles p.chatauthor.other {
	text-align: left;
}

blockquote.chatbubbles div.chatbubble.self {
	margin-right: 5%;
	margin-left: auto;
	max-width: 80%;
}

blockquote.chatbubbles div.chatbubble.other {
	margin-left: 5%;
	margin-right: auto;
	max-width: 80%;
}

blockquote.chatbubbles div.chatbubble.self::after,
blockquote.chatbubbles div.chatbubble.other::after {
	content: '';
	position: absolute;
	bottom: 5px;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	z-index: 0;
}

blockquote.chatbubbles div.chatbubble.other::after {
	left: 0;
	border-right-color: currentColor;
	border-left: 0;
	margin-left: -4px;
}

blockquote.chatbubbles div.chatbubble.self::after {
	right: 0;
	border-left-color: currentColor;
	border-right: 0;
	margin-right: -4px;
}
blockquote.chatbubbles p.syntaxError {
	background-color: darkred;
	color: black;
}