.jsPopup {
	bottom: calc(100% + 1rem);
	animation: 1.5s ease 0s 1 popup;
	transform-origin: 50% 70%;
	user-select: none;
	border: 0.1rem solid #303030BF;
	box-shadow: 0.2rem 0.2rem #00000090;
	border-radius: 0.5rem;
	font-weight: bold;
	font-size: 1rem;
	padding: 0.3rem;
	background-color: #f8f7e0;
	color: black;
}

.jsPopup.info {
	background-color: paleturquoise;
}
.jsPopup.success {
	background-color: palegreen;
}
.jsPopup.warning {
	background-color: palegoldenrod;
}
.jsPopup.error {
	background-color: palevioletred;
}

.jsPopup:hover {
	animation-play-state: paused;
}

.jsPopupContainer {
	position: absolute;
	top: 1rem;
	left: 0;
	right: 0;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
}

.jsPopupHost {
	position: relative;
}

@keyframes popup {
	from {
		transform: scale(0);
		opacity: 0;
	}

	10% {
		transform: none;
		opacity: 100%;
	}

	75% {
		transform: none;
		opacity: 100%;
	}

	to {
		transform: scale(0);
		opacity: 0;
	}
}
