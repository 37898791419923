#app {
    min-height: 100dvh;
    position: absolute;
    padding: 2rem;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    box-sizing: border-box;
    flex-flow: row nowrap;
}

#input {
    position: sticky;
    top: 2rem;
    height: calc(100dvh - 4rem);
    flex: 1 0 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}

#input button {
    font-size: 125%
}

#author {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
#authorLabel {
    flex: 0 0 auto;
    margin-right: 0.5rem;
}
#authorInput {
    flex: 1 1 auto;
}

#contents {
    flex: 1 0 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}
#contentsLabel {
    flex: 0 0 auto;
}
#contentsInput {
    flex: 1 0 auto;
}

#output {
    flex: 1 0 0;
    display: flex;;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

#helpDialog::backdrop {
    background-color: rgba(0.2, 0.2, 0.2, 0.7)
}

#helpDialog {
    border: 1px gray outset;
    max-width: 100svw;
    width: 500px;
    box-sizing: border-box;
    overflow: auto;
}

#helpDialog .helpContents {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
}

#helpDialog .helpContents h2 {
    margin: 0;
}

#helpDialog .helpContents blockquote {
    overflow: auto;
    max-height: calc(90svh - 60px - 5rem);

}

#helpDialog .helpContents button {
    font-size: 125%;
}

.workSkinButton {
    align-self: center;
    padding: 0.2rem 0.5rem;
    font-size: 125%;
}